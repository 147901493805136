/* eslint-disable prefer-template */
// 涉及到跳转、外部资源引用、接口调用
// taocheche.com跳到taocheche.com  taocheche.com跳到taocheche.com
// param@isTest{Boolean} 是否根据当前网址是否test环境， 对url增加上测试环境标识， 默认是替换
// 举例： 1.不传递就不会test转换
//       2.test:会增加test.   //bangmai.taocheche.com替换成//test.bangmai.taocheche.com
//       3.test-:会增加test-  //bangmai.taocheche.com替换成//bangmai-test.taocheche.com
//       4.uat:会增加uat-     //bangmai.taocheche.com替换成//uat-bangmai.taocheche.com
export function getNewUrl(url, isTest = true) {
  if (!url || typeof window === "undefined") return url;
  if (url && url.indexOf("//m.daikuan.com") > -1) return url; // 这些url不用替换，保持原样

  // const reg = new RegExp(/(\/\/yiche\.)|(\.yiche\.)/) // 判断m的正则
  // const domainkey = reg.test(window?.location?.href)
  const domainkey = true;
  let nurl = url.replace(/\.(taoche|taocheche)\.(com|cn)/, ".taocheche.com"); // 替换taoche->taocheche 为当前网页的标识
  const nurlqian = nurl.split("?")[0];

  // 线上域名 根据是否测试环境，拼接上测试标识
  const href2 = window.location.href ? window.location.href.split("?")[0] : "";
  // 当前是测试环境
  if (
    isTest &&
    nurl.substr(0, 1) !== "." && // 这类是种cookie的值， 不需要拼接测试环境标识 ，比如：.taocheche.com
    (/\/\/bangmai-test\./.test(href2) || // 找车帮买
      /passportuat\./.test(href2) ||
      /consumeruat\./.test(href2) ||
      /\/\/([\w-]+\.)?test\./.test(href2) ||
      /\/\/uat-static\./.test(href2))
  ) {
    // 访问的url里有yiche标识
    if (domainkey) {
      // 要处理的url里含有m标识，则替换， 因为测试环境都是.yiche.线上是-yiche. 所以独立出来
      if (/\.m\./.test(nurlqian)) {
        nurl = nurl.replace(/\.m\./, ".yiche.");
      } else if (/\/\/m\./.test(nurlqian)) {
        nurl = nurl.replace(/\/\/m\./, "//yiche.");
      } else if (/^m\./.test(nurlqian)) {
        nurl = nurl.replace(/^m\./, "yiche.");
      }
    }

    // 要处理的url是非测试环境， 则进行测试环境处理
    if (
      !(
        /consumeruat\./.test(nurlqian) ||
        /passportuat\./.test(nurlqian) ||
        /(\.)?test\./.test(nurlqian) ||
        /bangmai-test\./.test(nurlqian) ||
        /uat-static\./.test(nurlqian)
      )
    ) {
      if (/\/\/bangmai\./.test(nurlqian)) {
        nurl = nurl.replace(/\/\/bangmai\./, "//bangmai-test.");
      } else if (/\/\/proconsumer\./.test(nurlqian)) {
        // 接口的域名替换方式
        nurl = nurl.replace(/\/\/proconsumer\./, "//consumeruat.");
      } else if (/\/\/passport\./.test(nurlqian)) {
        // 接口的域名替换方式
        nurl = nurl.replace(/\/\/passport\./, "//passportuat.");
      } else if (/\/\/static\./.test(nurlqian)) {
        nurl = nurl.replace(/\/\//, "//uat-");
      } else if (/\/\/www\./.test(nurlqian)) {
        // 这个场景暂未出现，先这样写
        nurl = nurl.replace(/\/\/www\./, "//test.");
      } else if (
        /\/\/([\w-]+\.)?((m|yiche)\.(taoche|taocheche)\.com)/.test(nurlqian)
      ) {
        // beijing.m.taocheche.com替换成beijing.test.m.taocheche.com
        nurl = nurl.replace(
          /\/\/([\w-]+\.)?((m|yiche)\.(taoche|taocheche)\.com)/,
          "//$1test.$2"
        );
      } else {
        nurl = nurl.replace(/\/\//, "//test.");
      }
    }

    // 线上环境
  } else {
    // 访问的url里有yiche标识
    // eslint-disable-next-line no-lonely-if
    if (domainkey) {
      // 要处理的url里含有m标识，则替换
      if (/(car|jinrong|event|passport|bangmai)\.m\./.test(nurlqian)) {
        nurl = nurl.replace(/\.m\./, "-yiche.");
      } else if (/\.m\./.test(nurlqian)) {
        nurl = nurl.replace(/\.m\./, ".yiche.");
      } else if (/\/\/m\./.test(nurlqian)) {
        nurl = nurl.replace(/\/\/m\./, "//yiche.");
      } else if (/^m\./.test(nurlqian)) {
        nurl = nurl.replace(/^m\./, "yiche.");
      }
    }
  }

  return nurl;
}
