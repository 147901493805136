"use client";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Controller } from "swiper";
import useRouter from "@/hooks/useRouter";
import Image from "next/image";
import useReport from "@/hooks/useReport";
import { useState } from "react";
import "swiper/css";
import "swiper/css/autoplay";

import s from "./index.module.css";

// param@datas{Array} 轮播图片 格式：
// [
//    {picUrl: 'https://static.zhongan.com/website/health/zarm/images/banners/3.png', clickUrl:'跳转的url'},
//    {url: '/lunbo/home01.png', clickUrl:'跳转的url222'}
// ]
// param@path{String} 更多的跳转路径 格式：/company
// param@swiperClass{Object} swiper的样式，值如：s.class
// param@propStyle{Object} style样式， 用于调整跑马灯的css属性
// param@pageClass{Object} 调整翻页条的样式，值如：s.pageClass， 主要用于翻页的位置
// param@reportId{String} 焦点图的埋点ID
const SwiperNew = ({
  datas = [],
  swiperClass = 0,
  propStyle = {},
  pageClass,
  reportId,
}) => {
  const [current, setCurrent] = useState(0);
  const { reportClick } = useReport();
  const router = useRouter();

  return (
    <div className={`${s.wai} ${swiperClass} swiperContainer`}>
      <Swiper
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        loop
        loopedSlides={datas.length }
        onActiveIndexChange={(swiper) => {
          setCurrent(swiper.activeIndex % datas.length);
        }}
        modules={[Autoplay, Controller]}
        className={`${swiperClass}`}
        style={{ ...propStyle }}
      >
        {datas?.map((item = {}, i) => {
          return (
            <SwiperSlide
              className={s.carousel__item__pic}
              key={+i}
              onClick={() => {
                if (reportId) {
                  reportClick(reportId, {
                    cpos: i,
                  });
                }

                if (item?.clickUrl) router.push(item?.clickUrl);
              }}
            >
              <Image
                alt="放心车二手车就选淘车车"
                src={item?.picUrl}
                draggable={false}
                fill
              />
            </SwiperSlide>
          );
        })}

        <div className={`${s.navigation}${pageClass ? ` ${pageClass}` : ""}`}>
          {datas?.map((item = {}, i) => {
            return (
              <div
                key={`swiperpage_${i}`}
                className={` ${s.swiperPaginationBullet} ${
                  i === current ? s.swiperactive : ""
                }`}
              ></div>
            );
          })}
        </div>
      </Swiper>
    </div>
  );
};

export default SwiperNew;
