/*
 * @Description: 唤醒app
 * @Author: lh
 * @Date: 2023-12-15 11:40:45
 * @LastEditTime: 2023-12-25 21:13:27
 * @LastEditors: lh
 */

import { getCookie, setCookie } from "./cookie";
//import { getNewUrl } from './getNewUrl';
import CallApp, { getUrlParams, getPagePath } from "./callApp";
import copy from "clipboard-copy";

const callApp = ({ innerfromid, hckukuid, toappid, value }) => {
  const url = `https://static.taocheche.com/taoche-h5/downloadApp/index.html?innerfromid=${innerfromid}&hckukuid=${hckukuid}&toappid=${toappid}`;

  try {
    const ua = (navigator && navigator.userAgent) || "";
    const { hckukuid, innerfromid, toappid } = getUrlParams("", url);
    const path = getPagePath(toappid);
    copy(`hckuku{$#${hckukuid}@`);

    if (/iphone|ipad|ipod/i.test(ua)) {
      setCookie("innerfromid", innerfromid, 1);

      if (path == "openCarDetail") {
        window.location.href = `https://t.taocheche.com/usedcar/bitautousedcar://?platform=web&action=${path}&value=${value}&hckukuid=${hckukuid}&innerfromid=${innerfromid}`;
        return;
      }
      window.location.href = `https://t.taocheche.com/usedcar/bitautousedcar://?platform=web&action=${path}&hckukuid=${hckukuid}&innerfromid=${innerfromid}`;
    } else {
      const params = `innerfromid=${innerfromid}`;
      const callLib = new CallApp();
      callLib.open({
        path,
        params,
        hckukuid,
        downloadPageUrl: url,
      });
    }
  } catch (err) {
    console.log(url);
  }
};

export default callApp;
