"use client";
import React from "react";
import style from "./index.module.css";

export default function GoTop() {
  const handGoTop = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  };
  return <div className={style.go_top} onClick={handGoTop} />;
}
