import { getNewUrl } from "./getNewUrl";

export function getCookie(e) {
  let t = void 0;
  var i = new RegExp("(^| )" + e + "=([^;]*)(;|$)");
  return (t = document.cookie.match(i)) ? unescape(t[2]) : null;
}
export function setCookie(e, t, i = 24, n = getNewUrl(".taocheche.com")) {
  var a = 1,
    r = "",
    d = new Date();
  void 0 != i && "" != i
    ? d.setTime(d.getTime() + i * a * 60 * 60 * 1e3)
    : d.setTime(d.getTime() + 24 * a * 60 * 60 * 1e3);
  n && (r = ";domain=" + n);
  d = d.toGMTString();
  if (i === 0) {
    document.cookie = e + "=" + escape(t) + ";path=/;" + r;
  } else {
    document.cookie = e + "=" + escape(t) + ";path=/;expires=" + d + r;
  }
}

export default { getCookie, setCookie };
