/*
 * @Description: APP内打开
 * @Author: lh
 * @Date: 2023-12-12 15:46:38
 * @LastEditTime: 2023-12-25 18:09:30
 * @LastEditors: lh
 */

import React from "react";
import s from "./index.module.css";
import CallApp from "@/components/CallApp";
import OpenApp from "@/components/OpenApp";

const AppOpen = ({ innerfromid, hckukuid, toappid = "openHome", value }) => {
  // const onTo = () => {
  //   CallApp({innerfromid,hckukuid,toappid,value});
  // }
  let schemeURL = `bitautousedcar://?platform=web&action=${toappid}`;
  if (value) {
    schemeURL += `&value=${value}`;
  }
  return (
    <div className={s.content}>
      <OpenApp
        className={s.openApp}
        hckukuid={hckukuid}
        innerfromId={innerfromid}
        schemeURL={schemeURL}
        // onClick={onTo}
      >
        App内打开
      </OpenApp>
    </div>
  );
};

export default AppOpen;
